<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>
      <div
        class="flex flex-col items-center justify-start flex-grow w-full text-yellow-50 p-3"
      >
        <div class="flex-grow"></div>
        <footer>
          <StickFooter />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  components: {
    ConnectWallet,
    StickFooter,
  },
  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },
  },
  created() {},
};
</script>
